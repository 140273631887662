import React, { useEffect } from 'react';
import { useAccount, useFeatureGuardController } from '@knowns/common';

export const FeatureController: React.FC = () => {
  const { activeAccount } = useAccount();
  const { setRules } = useFeatureGuardController();

  useEffect(() => {
    if (!activeAccount) return;
    setRules({
      casualResearch: {
        access: activeAccount.activeContract.canCasual,
        analytics: activeAccount.activeContract.canCasualResult,
      },
      cm: {
        access: activeAccount.activeContract.canCm,
      },
      compare: {
        access: activeAccount.activeContract.canCompare,
      },
      film: {
        access: activeAccount.activeContract.canMovie,
      },
      generation: {
        access: activeAccount.activeContract.canGen,
      },
      meal: {
        access: activeAccount.activeContract.canMeal,
      },
      susClu: {
        access: activeAccount.activeContract.canSusClu,
      },
      restaurant: {
        access: activeAccount.activeContract.canRestaurant,
      },
      original: {
        access: activeAccount.activeContract.canOriginal,
      },
    });
  }, [activeAccount, setRules]);

  return null;
};
