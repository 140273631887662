import React, { lazy, Suspense, useEffect, useLayoutEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AppMenuBar } from '@app';
import {
  APP_URL,
  FeatureGuard,
  FixedColumn,
  FullScreen,
  IsAuthedRedirect,
  Loader,
  LoginCallback,
  SecureRoute,
  storage,
  useAccount,
  useApi,
  useAuth,
  useSessionHistory,
  useToast,
} from '@knowns/common';

const MaintenancePage = lazy(() => import('@pages/maintenance'));
const LoginPage = lazy(() => import('@pages/login'));
const SignupPage = lazy(() => import('@pages/signup'));
const AccountSelectPage = lazy(() => import('@pages/account-select'));
const TopPage = lazy(() => import('@pages/index'));
const ServiceNotificationPage = lazy(() => import('@pages/notifications'));
const ServiceNotificationDetailPage = lazy(() => import('@pages/notifications/id'));
const SearchPage = lazy(() => import('@pages/search'));
const AnalyticsBrandsPage = lazy(() => import('@pages/analytics/bb/id'));
const AnalyticsBrandsGenrePage = lazy(() => import('@pages/analytics/bbc/id'));
const AnalyticsRestaurantListPage = lazy(() => import('@pages/analytics/restaurant'));
const AnalyticsRestaurantPage = lazy(() => import('@pages/analytics/restaurant/id'));
const AnalyticsFilmListPage = lazy(() => import('@pages/analytics/film'));
const AnalyticsFilmPage = lazy(() => import('@pages/analytics/film/id'));

const SettingsUserHistoriesPage = lazy(() => import('@pages/settings/user/histories'));

const HelpPage = lazy(() => import('@pages/help'));
const HelpSupportsMailPage = lazy(() => import('@pages/help/supports/mail'));
const HelpSupportsMailDonePage = lazy(() => import('@pages/help/supports/mail/done'));
const HelpRequestsKeywordPage = lazy(() => import('@pages/help/requests/keyword'));
const HelpRequestsKeywordDonePage = lazy(() => import('@pages/help/requests/keyword/done'));
const SettingsPage = lazy(() => import('@pages/settings'));

const SettingsAccountsConttractPage = lazy(() => import('@pages/settings/accounts/contract'));
const SettingsUserPage = lazy(() => import('@pages/settings/user'));
const SettingsAccountsMembersPage = lazy(() => import('@pages/settings/accounts/members'));
const SettingsAcfountsMembersInvitationPage = lazy(
  () => import('@pages/settings/accounts/members/invitation')
);
const SettingsAccountsBillingsContractPage = lazy(
  () => import('@pages/settings/accounts/billings/contract')
);
const SettingsAccountsBillingsCardPage = lazy(
  () => import('@pages/settings/accounts/billings/card')
);
const SettingsUserBookmarksPage = lazy(() => import('@pages/settings/user/bookmarks'));
const SettingsAccountsBillingsPage = lazy(() => import('@pages/settings/accounts/billings'));

const CasualResearchPage = lazy(() => import('@pages/casual-research'));
const CasualResearchDetailPage = lazy(() => import('@pages/casual-research/id'));
const CasualResearchDetailOriginalPage = lazy(() => import('@pages/casual-research/original'));

export const AppRoutes: React.FC = () => {
  const { config } = useApi();
  const { isAuthenticated, onAuthRequired } = useAuth();

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          exact
          path={APP_URL.LOGIN}
          component={
            config.authorization !== undefined && isAuthenticated ? IsAuthedRedirect : LoginPage
          }
        />
        <Route path={`/:activationId${APP_URL.SIGNUP}`} component={SignupPage} />
        <Route path={APP_URL.LOGIN_CALLBACK} component={LoginCallback} />
        <Route exact path={APP_URL.MAINTENANCE} component={MaintenancePage} />
        <SecureRoute path='*' component={AppSecureRoutes} onAuthRequired={onAuthRequired} />
      </Switch>
    </Suspense>
  );
};

// AppSecureRoutes Access Control Memo:
// 1. ログインできていない場合は、 LOGIN にリダイレクト
// 2. ログイン後accountIdを選択できていない場合は、 SELECT_ACCOUNT にリダイレクト
// 3. ログイン後accountIdを選択できている場合
//   3-1. redirectUrl(401エラーでログイン画面に戻される際にr=がある)場合、そのURLにリダイレクト
//   3-2. 3-1が当てはまらない場合、TOPにリダイレクト
const AppSecureRoutes: React.FC = () => {
  const { accountId, activeAccount } = useAccount();
  const history = useHistory();
  useSessionHistory();

  // 3-1.
  useLayoutEffect(() => {
    if (accountId === null) return;
    const redirectUrl = storage.session.get('redirectUrl');
    if (!redirectUrl) return;
    storage.session.remove('redirectUrl');
    history.replace(redirectUrl);
  }, [accountId, history]);

  // Account選択前
  if (accountId === null)
    return (
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={APP_URL.SELECT_ACCOUNT} component={AccountSelectPage} />
          <Redirect path='*' to={APP_URL.SELECT_ACCOUNT} />
        </Switch>
      </Suspense>
    );

  if (activeAccount == null) {
    return (
      <FullScreen center>
        <Loader variant='screen' />
      </FullScreen>
    );
  }

  // Account選択後
  return (
    <Suspense fallback={null}>
      <Switch>
        <RouteWithMenuBar path={APP_URL.ANALYTICS_FILM()} component={AnalyticsFilmPage}>
          <FeatureGuard type='film/access' fallback={<CanNotAccess />}>
            <AnalyticsFilmPage />
          </FeatureGuard>
        </RouteWithMenuBar>
        <RouteWithMenuBar exact path={APP_URL.ANALYTICS_FILM_LIST}>
          <FeatureGuard type='film/access' fallback={<CanNotAccess />}>
            <AnalyticsFilmListPage />
          </FeatureGuard>
        </RouteWithMenuBar>
        <RouteWithMenuBar path={APP_URL.ANALYTICS_RESTAURANT()}>
          <FeatureGuard type='restaurant/access' fallback={<CanNotAccess />}>
            <AnalyticsRestaurantPage />
          </FeatureGuard>
        </RouteWithMenuBar>

        <RouteWithMenuBar exact path={APP_URL.ANALYTICS_RESTAURANT_LIST}>
          <FeatureGuard type='restaurant/access' fallback={<CanNotAccess />}>
            <AnalyticsRestaurantListPage />
          </FeatureGuard>
        </RouteWithMenuBar>
        <RouteWithMenuBar path={APP_URL.ANALYTICS_BBC()} component={AnalyticsBrandsGenrePage} />
        <RouteWithMenuBar path={APP_URL.ANALYTICS_BB()} component={AnalyticsBrandsPage} />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_USER_BOOKMARKS}
          component={SettingsUserBookmarksPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_USER_HISTORIES}
          component={SettingsUserHistoriesPage}
        />
        <RouteWithMenuBar exact path={APP_URL.HELP} component={HelpPage} />
        <RouteWithMenuBar
          exact
          path={APP_URL.HELP_SUPPORTS_MAIL}
          component={HelpSupportsMailPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.HELP_SUPPORTS_MAIL_DONE}
          component={HelpSupportsMailDonePage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.HELP_REQUESTS_KEYWORD}
          component={HelpRequestsKeywordPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.HELP_REQUESTS_KEYWORD_DONE}
          component={HelpRequestsKeywordDonePage}
        />
        <RouteWithMenuBar exact path={APP_URL.SETTINGS_USER} component={SettingsUserPage} />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_MEMBERS_INVITATION}
          component={SettingsAcfountsMembersInvitationPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_MEMBERS}
          component={SettingsAccountsMembersPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_BILLINGS}
          component={SettingsAccountsBillingsPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_BILLINGS_CARD}
          component={SettingsAccountsBillingsCardPage}
        />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_BILLINGS_CONTRACT}
          component={SettingsAccountsBillingsContractPage}
        />
        <RouteWithMenuBar exact path={APP_URL.SETTINGS} component={SettingsPage} />
        <RouteWithMenuBar
          exact
          path={APP_URL.SETTINGS_ACCOUNTS_CONTRACT}
          component={SettingsAccountsConttractPage}
        />
        <RouteWithMenuBar exact path={APP_URL.SEARCH} component={SearchPage} />
        <RouteWithMenuBar
          path={APP_URL.NOTIFICATION_DETAIL()}
          component={ServiceNotificationDetailPage}
        />
        <RouteWithMenuBar exact path={APP_URL.NOTIFICATIONS} component={ServiceNotificationPage} />
        <RouteWithMenuBar exact path={APP_URL.TOP} component={TopPage} />

        <RouteWithMenuBar exact path={APP_URL.CASUAL_RESEARCH} component={CasualResearchPage} />
        <RouteWithMenuBar
          path={APP_URL.CASUAL_RESEARCH_DETAIL_ORIGINAL()}
          component={CasualResearchDetailOriginalPage}
        />
        <RouteWithMenuBar
          path={APP_URL.CASUAL_RESEARCH_DETAIL()}
          component={CasualResearchDetailPage}
        />

        <Redirect to={APP_URL.TOP} />
      </Switch>
    </Suspense>
  );
};

const RouteWithMenuBar: React.FC<
  React.ComponentProps<typeof Route> & { children?: React.ReactNode }
> = ({ component: Component, children, ...props }) =>
  children ? (
    <Route {...props}>
      <FixedColumn fixed='left'>
        <AppMenuBar />
        <div>{children}</div>
      </FixedColumn>
    </Route>
  ) : (
    <Route
      {...props}
      render={(routeProps) => (
        <FixedColumn fixed='left'>
          <AppMenuBar />
          <div>{Component && <Component {...routeProps} />}</div>
        </FixedColumn>
      )}
    />
  );

const CanNotAccess: React.FC = () => {
  const { error } = useToast();
  const initialRef = useRef(true);
  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      error('アクセス権限がありません。');
    }
  }, [error]);
  return <Redirect to={APP_URL.TOP} />;
};
